import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout     from '../components/layout'
import Wrapper    from '../components/Wrapper'
import Hero       from '../components/Hero'
import PostsList  from '../components/PostsList'
import Pagination from '../components/Pagination'
import SEO        from '../components/SEO'
import Article    from "../components/Article";
import Header     from "../components/Header";

class BlogList extends React.Component {
    render() {
        const siteTitle       = get(this, 'props.data.site.siteMetadata.title')
        const posts           = get(this, 'props.data.allMarkdownRemark.edges')
        const { pageContext } = this.props

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    fontFamily: 'georgia, "times new roman", serif',
                    fontSize: 16,
                }}
            >
                <Header/>

                <div style={{ maxWidth: 700, padding: 20 }}>
                    <div style={{width: '100%', marginTop: 20, marginBottom: 50, opacity: 0.4, textAlign: 'center', backgroundColor: 'white', height: 12, borderBottom: '1px solid grey'}}>
                    </div>
                    <PostsList posts={posts}/>
                </div>

                <Pagination
                    nbPages={pageContext.nbPages}
                    currentPage={pageContext.currentPage}
                />

            </div>
        )
    }
}

export default BlogList

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { ne: "page" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            language
            slug
          }
        }
      }
    }
  }
`
