import React from 'react';
import { Link } from 'gatsby';


export default class PostsListItem extends React.Component {

    render() {
        const { title, excerpt, slug, date} = this.props;

        return (
            <div style={{ width: '100%', marginBottom: 90 }}>
                <div
                    style={{
                        marginBottom: 20,
                    }}
                >
                    <Link
                        style={{
                            fontFamily: '"Hoefler Text", georgia, "times new roman", serif',
                            fontWeight: 200,
                            fontSize: 24,
                            color: '#443337',
                            textDecoration: 'none'
                        }}
                        to={slug}
                    >
                        {title}
                    </Link>
                </div>
                <section>
                    <div dangerouslySetInnerHTML={{ __html: excerpt }}/>
                </section>
                <footer>
                    <div style={{ opacity: 0.4, textAlign: 'right' }}>{date}</div>
                </footer>
            </div>
        )
    }

}
